import { CheckIcon, ChevronsUpDown } from 'lucide-react'
import { useState, forwardRef } from 'react'
import * as React from 'react'
import * as RPNInput from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import { twMerge } from 'tailwind-merge'
import { Button } from '../ui/button'
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from '../ui/command'
import { Input, type InputProps } from '../ui/input'
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover'
import { ScrollArea } from '../ui/scroll-area'
import { inputClassname } from './form-classes'

type CountrySelectOption = { label: string; value: RPNInput.Country }

type CountrySelectProps = {
	disabled?: boolean
	value: RPNInput.Country
	onChange: (value: RPNInput.Country) => void
	options: CountrySelectOption[]
}

const CountrySelect = ({
	disabled,
	value,
	onChange,
	options,
}: CountrySelectProps) => {
	const handleSelect = React.useCallback(
		(country: RPNInput.Country) => {
			onChange(country)
		},
		[onChange],
	)

	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					type="button"
					variant="outline"
					className={twMerge(
						inputClassname,
						'flex flex-none gap-1 rounded-e-none rounded-s-md px-3',
					)}
					disabled={disabled}
				>
					<FlagComponent country={value} countryName={value} />
					<ChevronsUpDown
						className={twMerge(
							'-mr-2 h-4 w-4 text-black dark:text-white',
							disabled ? 'hidden' : 'opacity-100',
						)}
					/>
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-[300px] p-0">
				<Command>
					<CommandList>
						<ScrollArea className="h-72">
							<CommandInput placeholder="Search country..." />
							<CommandEmpty>No country found.</CommandEmpty>
							<CommandGroup>
								{options
									.filter((x) => x.value)
									.map((option) => (
										<CommandItem
											className="cursor-pointer gap-2 bg-gray-50 dark:bg-neutral-900"
											key={option.value}
											onSelect={() => handleSelect(option.value)}
										>
											<FlagComponent
												country={option.value}
												countryName={option.label}
											/>
											<span className="flex-1 text-sm">
												{String(
													new Intl.DisplayNames(['en'], { type: 'region' }).of(
														option.label,
													),
												)}
											</span>
											{option.value && (
												<span className="text-sm text-foreground/50">
													{`+${RPNInput.getCountryCallingCode(option.value)}`}
												</span>
											)}
											<CheckIcon
												className={twMerge(
													'ml-auto h-4 w-4',
													option.value === value ? 'opacity-100' : 'opacity-0',
												)}
											/>
										</CommandItem>
									))}
							</CommandGroup>
						</ScrollArea>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	)
}

export const FlagComponent = ({ country, countryName }: RPNInput.FlagProps) => {
	const Flag = flags[country]

	return (
		<span className="flex h-4 w-6 overflow-hidden rounded-sm">
			{Flag && <Flag title={countryName} />}
		</span>
	)
}

export const PhoneInput = forwardRef<
	HTMLInputElement,
	InputProps & { onUpdateNumber?: (value: string) => void }
>(({ className, onUpdateNumber, ...props }, ref) => {
	const [countryInfo, setCountryInfo] = useState<RPNInput.FlagProps>({
		// Do this so we don't have to default the country
		country: '' as RPNInput.FlagProps['country'],
		countryName: '',
	})

	return (
		<div className="flex">
			<CountrySelect
				disabled={false}
				value={countryInfo.country}
				onChange={(c) =>
					setCountryInfo({
						country: c,
						countryName: String(
							new Intl.DisplayNames(['en'], { type: 'region' }).of(c),
						),
					})
				}
				options={RPNInput.getCountries().map((c) => ({
					label: c,
					value: c as RPNInput.Country,
				}))}
			/>
			<Input
				ref={ref}
				{...props}
				onBlur={(v) => {
					const currentCallingCode = RPNInput.getCountryCallingCode(
						countryInfo.country,
					)
					// If the calling code already exists, just replace it
					return onUpdateNumber?.(
						'+' +
							currentCallingCode +
							v.currentTarget.value.replace(`+${currentCallingCode}`, ''),
					)
				}}
				className={twMerge(
					inputClassname,
					'rounded-e-md rounded-s-none border-l-0',
				)}
			/>
		</div>
	)
})
