import { type FormApi, useField } from '@rvf/react-router'
import { LoaderCircle } from 'lucide-react'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import { match } from 'ts-pattern'
import { Badge } from '../ui/badge'
import { Input as ShadcnInput } from '../ui/input'
import { Label } from '../ui/label'
import { Switch } from '../ui/switch'
import { Textarea as ShadcnTextarea } from '../ui/textarea'
import { ErrorMessage } from './error-message'
import {
	inputClassname as baseInputClassname,
	labelClassname,
} from './form-classes'
import { PhoneInput } from './phone-input'

export type RVFInputFieldProps = {
	label: string
	name: string
	disabled?: boolean
	// TODO: Replace this with generics
	form: FormApi<any>
	type?: HTMLInputElement['type']
	className?: string
	inputClassname?: string
	placeholder?: string
	loading?: boolean
	style?: React.HTMLAttributes<React.HTMLInputTypeAttribute>['style']
	subComponent?: React.ReactElement
	multiple?: boolean
	onChange?: (e: any) => void
}

export const RVFInput = React.forwardRef<
	React.ElementRef<typeof ShadcnInput>,
	RVFInputFieldProps
>(
	(
		{
			label,
			form,
			name,
			placeholder,
			className,
			inputClassname,
			loading,
			type,
			style,
			disabled,
			subComponent,
			multiple,
			onChange,
		},
		_ref,
	) => {
		const { error, getInputProps } = useField(form.scope(name), {
			validationBehavior: {
				initial: 'onBlur',
				whenTouched: 'onBlur',
				whenSubmitted: 'onSubmit',
			},
		})
		return (
			<div className={className}>
				{!(type === 'boolean') && (
					<Label htmlFor={name} className={labelClassname}>
						{label}
						{loading && <LoaderCircle className="animate-spin" />}
					</Label>
				)}
				{match(type)
					.with('textarea', () => (
						<ShadcnTextarea
							{...getInputProps()}
							placeholder={placeholder}
							className={twMerge(baseInputClassname, inputClassname)}
							style={style}
							disabled={disabled}
						/>
					))
					.with('phoneNumber', () => (
						<PhoneInput
							{...getInputProps()}
							name={name}
							onUpdateNumber={(v: string) => {
								form.setValue(name, v)
							}}
							placeholder={placeholder}
							disabled={disabled}
						/>
					))
					.with('file', () => (
						<ShadcnInput
							{...getInputProps()}
							name={name}
							className={twMerge(baseInputClassname, inputClassname)}
							type="file"
							style={style}
							disabled={disabled}
							multiple={multiple}
						/>
					))
					.with('boolean', () => (
						<div className="flex items-center gap-x-2">
							<Switch
								{...getInputProps}
								disabled={disabled}
								onCheckedChange={onChange}
							/>
							<Label htmlFor={name} className={labelClassname}>
								{label}
							</Label>
						</div>
					))
					.with('currency', () => (
						<div className="relative">
							<ShadcnInput
								type="number"
								min="0.01"
								step="0.01"
								placeholder={placeholder}
								className={twMerge('pl-5', baseInputClassname, inputClassname)}
								{...getInputProps()}
								style={style}
								disabled={disabled}
							/>
							<p className="absolute top-2.5 ml-2">$</p>
							<Badge className="absolute bottom-2 right-2 rounded-md bg-neutral-500 hover:bg-neutral-500">
								USD
							</Badge>
						</div>
					))
					.otherwise(() => (
						<div className="relative">
							<ShadcnInput
								type={type}
								placeholder={placeholder}
								className={twMerge(baseInputClassname, inputClassname)}
								{...getInputProps()}
								style={style}
								disabled={disabled}
							/>
							{subComponent}
						</div>
					))}
				<ErrorMessage error={error() as string} />
			</div>
		)
	},
)
